<template>
  <div class="d-flex justify-content-center">
    <fa-icon :icon="['fad', 'spinner-third']" :spin="true" size="2x" />
  </div>
  
</template>

<script>
export default {
  name: 'SpinnerComponent'
}
</script>